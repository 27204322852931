import * as React from 'react'
import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 60"
    width="70"
    height="60"
    fill="none"
  >
    <path
      fill="#2274A3"
      d="M33.897 10.19c-1.502 1.389-3.038 3.055-4.552 4.948 11.44.627 18.954 5.609 18.954 12.797 0 1.815-.478 3.742-1.423 5.73a57.78 57.78 0 0 1-6.975 11.192c-1.51 1.893-3.05 3.56-4.551 4.952-3.272 3.029-6.349 4.778-8.494 4.778-4.769 0-14.163-8.594-20.02-20.921-.944-1.989-1.423-3.916-1.423-5.73 0-6.789 6.71-11.61 17.096-12.65a60.73 60.73 0 0 1 4.347-5.63C10.791 9.656 0 17 0 27.936c0 2.623.657 5.334 1.95 8.053C7.475 47.63 18.087 60 26.86 60c5.009 0 10.609-4.033 15.53-9.655a60.77 60.77 0 0 0 4.352-5.63 65.064 65.064 0 0 0 5.034-8.72c1.292-2.72 1.945-5.43 1.945-8.054 0-9.247-7.732-15.904-19.824-17.75Z"
    />
    <path
      fill="#8BC53F"
      d="M67.3 24.01C61.77 12.37 51.163 0 42.39 0c-5.008 0-10.613 4.034-15.53 9.655a60.732 60.732 0 0 0-4.347 5.631 64.841 64.841 0 0 0-5.034 8.724c-1.293 2.72-1.95 5.43-1.95 8.05 0 9.242 7.733 15.904 19.82 17.75 1.502-1.393 3.042-3.06 4.552-4.953-11.44-.626-18.959-5.608-18.959-12.797 0-1.814.479-3.742 1.423-5.73a57.86 57.86 0 0 1 6.975-11.192c1.51-1.893 3.05-3.56 4.552-4.947 3.272-3.029 6.348-4.778 8.493-4.778 4.77 0 14.164 8.594 20.02 20.917.945 1.988 1.424 3.916 1.424 5.73 0 6.788-6.71 11.605-17.092 12.65a59.463 59.463 0 0 1-4.352 5.63c16.061 0 26.857-7.345 26.857-18.28.004-2.62-.649-5.33-1.941-8.05Z"
    />
    <path
      fill="#616264"
      d="M34.626 35.803a6.418 6.418 0 0 1-6.41-6.41 6.418 6.418 0 0 1 6.41-6.41 6.418 6.418 0 0 1 6.41 6.41 6.418 6.418 0 0 1-6.41 6.41Zm0-11.066a4.657 4.657 0 0 0-4.651 4.652 4.657 4.657 0 0 0 4.651 4.651 4.657 4.657 0 0 0 4.652-4.651 4.66 4.66 0 0 0-4.652-4.652Z"
    />
  </svg>
)
