import 'core-js/stable'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter } from 'react-router-dom'
import {
  Modal,
  ThemeProvider,
  Skeleton,
  theme,
  colors,
  ErrorFallback,
} from '@voltus/core-components'
import { ddErrorHandler, initDD } from '@voltus/datadog'
import 'normalize.css'
import './index.scss'

import App from './App'
import INJECTION from './constants/injection'
import { routes } from './routes/routes'

const NODE_ENV = process.env.NODE_ENV

initDD('login')

function initApp() {
  routes.initRoutes()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  if (INJECTION) {
    Modal.setAppElement(INJECTION)

    const root = createRoot(INJECTION)
    root.render(
      <React.StrictMode>
        <ErrorBoundary
          onError={ddErrorHandler}
          FallbackComponent={ErrorFallback}
        >
          <BrowserRouter basename={process.env.BASE_PATH}>
            <Skeleton.Theme
              color={colors.grays['15']}
              highlightColor={colors.grays['10']}
            >
              <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </Skeleton.Theme>
          </BrowserRouter>
        </ErrorBoundary>
      </React.StrictMode>
    )
  }
}

initApp()
