export const TOAST_TYPE_SUCCESS = 'success'
export const TOAST_TYPE_ERROR = 'error'
export const TOAST_TYPE_INFO = 'info'
export const TOAST_TYPE_DEFAULT = 'default'
export const TOAST_TYPE_WARNING = 'warning'

export const TOAST_TYPES = {
  DEFAULT: TOAST_TYPE_DEFAULT,
  SUCCESS: TOAST_TYPE_SUCCESS,
  ERROR: TOAST_TYPE_ERROR,
  INFO: TOAST_TYPE_INFO,
  WARNING: TOAST_TYPE_WARNING,
} as const

export const TOAST_POSITION = {
  TOP_RIGHT: 'top-right',
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
} as const
