import classnames from 'classnames'
import * as React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {
  ToastContainer as ReactToastContainer,
  ToastContainerProps,
  ToastOptions,
  toast,
  Slide,
  Flip,
  Bounce,
  Zoom,
  cssTransition,
} from 'react-toastify'
import { ObjectValues } from '@voltus/types'
import { isTestEnv, renderNode } from '@voltus/utils'
import { StyledIcons } from '../../icons'
import { Box } from '../Box'
import { Flex } from '../Flex'
import { IconButton } from '../IconButton'
import { Text } from '../Text'
import { TOAST_POSITION, TOAST_TYPES } from './Toast.constants'
import stylesheet from './Toast.scss'

const SlideUp = cssTransition({
  enter: 'toast--slide-in',
  exit: 'toast--slide-out',
  collapseDuration: 0,
  collapse: false,
})

const noAnimation = cssTransition({
  enter: 'toast--no-animation',
  exit: 'toast--no-animation',
  collapse: false,
  collapseDuration: 0,
})

const CloseButton = ({ closeToast }) => {
  return (
    <IconButton
      isStrokeBased
      top={0}
      right={0}
      position="absolute"
      onClick={closeToast}
    >
      <StyledIcons.CircleXFilled secondaryColor="grays.5" color="transparent" />
    </IconButton>
  )
}

export const TOAST_CONTAINER_DEFAULTS: ToastContainerProps = {
  position: 'bottom-center',
  hideProgressBar: true,
  draggablePercent: 60,
  closeButton: CloseButton,
  closeOnClick: true,
  className: stylesheet.toastRoot,
  toastClassName: stylesheet.toastContainer,
  progressClassName: stylesheet.toastProgress,
  transition: SlideUp,
  // Allows for toasts to disappear even when the browser window
  // is not focused, this prevents toasts accumulating in the VOC
  // when the browser tab is not actively focused
  pauseOnFocusLoss: false,
  // Toast overload is real. Friends don't let friends get burned
  limit: 10,
}

export const ToastContainer = (props: ToastContainerProps): JSX.Element => {
  const testProps = isTestEnv() ? { transition: noAnimation } : {}
  return (
    <ReactToastContainer
      {...TOAST_CONTAINER_DEFAULTS}
      {...props}
      {...testProps}
    />
  )
}

type ToastProps = {
  type?: ObjectValues<typeof TOAST_TYPES>
  message?: React.ReactNode
  detail?: React.ReactNode
  children?: React.ReactNode
  icon?: string | React.ReactNode
}

interface ToastTransitions {
  SlideUp: typeof SlideUp
  Slide: typeof Slide
  Flip: typeof Flip
  Bounce: typeof Bounce
  Zoom: typeof Zoom
}

type ToastFn = (
  msg: React.ReactNode,
  detail?: React.ReactNode,
  options?: ToastOptions
) => void
export interface IToast extends React.FunctionComponent {
  (props?: React.PropsWithChildren<ToastProps>): JSX.Element
  Success: (props?: React.PropsWithChildren<ToastProps>) => JSX.Element
  Error: (props?: React.PropsWithChildren<ToastProps>) => JSX.Element
  Info: (props?: React.PropsWithChildren<ToastProps>) => JSX.Element
  Warning: (props?: React.PropsWithChildren<ToastProps>) => JSX.Element
  Default: (props?: React.PropsWithChildren<ToastProps>) => JSX.Element
  push: typeof toast
  dismiss: typeof toast.dismiss
  default: ToastFn
  info: ToastFn
  error: ToastFn
  success: ToastFn
  warning: ToastFn
  transitions: ToastTransitions
  POSITION: typeof TOAST_POSITION
}

const ToastConfigs = {
  default: {
    iconName: 'CircleInfo',
    accent: 'white',
    iconColor: 'grays.50',
  },
  success: {
    iconName: 'CircleCheck',
    accent: 'greens.30',
    iconColor: 'greens.30',
  },
  error: {
    iconName: 'TriangleAlert',
    accent: 'reds.30',
    iconColor: 'reds.30',
  },
  warning: {
    iconName: 'CircleExclamation',
    accent: 'golds.30',
    iconColor: 'golds.30',
  },
  info: {
    iconName: 'CircleInfo',
    accent: 'blues.50',
    iconColor: 'blues.50',
  },
} as const

export const Toast: IToast = ({
  children,
  message,
  detail,
  type = 'default',
  icon,
}: React.PropsWithChildren<ToastProps>): JSX.Element => {
  const m = message ?? children

  const config = ToastConfigs[type]
  if (!config) {
    throw new Error(
      `Invalid toast type. Must be one of ${Object.keys(ToastConfigs).join(
        ', '
      )}, but got ${type}`
    )
  }

  const { iconName, accent, iconColor } = config
  const DefaultIcon = StyledIcons[iconName]
  let Icon: null | React.ReactNode = <DefaultIcon />
  let isCustomIcon = false

  if (icon === null) {
    Icon = null
  } else if (typeof icon === 'string') {
    const IconComp = StyledIcons[icon]
    if (!IconComp) {
      throw new Error(
        'Unknown icon name - please pass a known icon name to the toast icon'
      )
    }

    Icon = <IconComp />
  } else if (typeof icon === 'object') {
    isCustomIcon = true
    Icon = icon
  }
  const renderDetail = (d: React.ReactNode) =>
    typeof d === 'string' ? <Text.Helper>{d}</Text.Helper> : renderNode(d)

  const hasIcon = !!Icon
  return (
    <Flex.Row height="100%">
      <Box width={4} bg={accent ?? 'white'} mr={hasIcon ? 0 : 2} />
      {Icon ? (
        // Hardcode 9x top padding so that icons line up properly
        // with the message text :(
        <Box pt="9px" px={1}>
          {isCustomIcon
            ? Icon
            : renderNode(Icon, {
                width: 16,
                height: 16,
                color: iconColor,
              })}
        </Box>
      ) : null}
      <div className={classnames(stylesheet.toastContent)}>
        {typeof m === 'string' ? <Text.Title>{m}</Text.Title> : renderNode(m)}
        {detail ? renderDetail(detail) : null}
      </div>
    </Flex.Row>
  )
}

// Alias <Toast type="default" /> to <Toast.Default>, etc.
Toast.Default = function ToastSuccess(
  props: React.PropsWithChildren<ToastProps>
): JSX.Element {
  return <Toast type="default" {...props} />
}
Toast.Success = function ToastSuccess(
  props: React.PropsWithChildren<ToastProps>
): JSX.Element {
  return <Toast type={TOAST_TYPES.SUCCESS} {...props} />
}
Toast.Error = function ToastError(
  props: React.PropsWithChildren<ToastProps>
): JSX.Element {
  return <Toast type={TOAST_TYPES.ERROR} {...props} />
}
Toast.Warning = function ToastWarning(
  props: React.PropsWithChildren<ToastProps>
): JSX.Element {
  return <Toast type={TOAST_TYPES.WARNING} {...props} />
}
Toast.Info = function ToastInfo(
  props: React.PropsWithChildren<ToastProps>
): JSX.Element {
  return <Toast type={TOAST_TYPES.INFO} {...props} />
}

// Convenience so consumer doesn't have to import react-toastify
Toast.push = toast
Toast.dismiss = toast.dismiss
Toast.POSITION = TOAST_POSITION

Toast.default = (msg, detail, options = {}) => {
  return toast(<Toast.Default message={msg} detail={detail} />, options)
}

Toast.info = (msg, detail, options = {}) => {
  return toast(<Toast.Info message={msg} detail={detail} />, options)
}

Toast.error = (msg, detail, options = {}) => {
  return toast(<Toast.Error message={msg} detail={detail} />, options)
}

Toast.success = (msg, detail, options = {}) => {
  return toast(<Toast.Success message={msg} detail={detail} />, options)
}

Toast.warning = (msg, detail, options = {}) => {
  return toast(<Toast.Warning message={msg} detail={detail} />, options)
}

Toast.transitions = {
  SlideUp,
  Slide,
  Flip,
  Bounce,
  Zoom,
}
